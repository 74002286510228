import React from 'react';


function Probare() {
    return (
        <div>
            <p className="p-title-info"><b>Probare</b></p>

            <p className="text-p-info">O PROBARE é uma iniciativa das três entidades representantes do mercado de Relacionamento no país: ABEMD
                - Associação Brasileira de Marketing Direto, ABRAREC - Associação Brasileira das Relações Empresa Cliente
                 e ABT - Associação Brasileira de Telesserviços, que identificaram a necessidade das definições de
                 parâmetros de auto-regulamentação no segmento, de forma a consolidar e aprimorar o atendimento aos
     Consumidores e aos Clientes Contratantes.</p>

            <p className="text-p-info">O PROBARE é gerenciado pelo Conselho Gestor e pelo Comitê Executivo.</p>

            <p className="text-p-info">O Conselho Gestor é responsável pelo direcionamento do PROBARE.</p>

            <p className="text-p-info"> O Comitê Executivo é  responsável pelo controle e acompanhamento do PROBARE e é composto pelo presidente de cada uma das
              associações ABEMD, ABRAREC, ABT; pelo coordenador do PROBARE e de um representante da Gerenciadora
      (sem direito a voto). </p>

            <p className="text-p-info">O PROBARE é composto por quatro componentes:</p>
            
            <div className="text-p-info componentes-probare">
                <div className="comp-prob"><span>1. Código de Ética</span></div>
                <div className="comp-prob"><span>2. Ouvidoria</span></div>
                <div className="comp-prob"><span>3. Selo de Ética</span></div>
                <div className="comp-prob"><span>4. Norma de Maturidade de Gestão</span></div>
            </div>
        </div>
    )
}

export default Probare;