import React from 'react';

function handleNorma(){
    document.getElementsByClassName("manipula-norma-gestao")[0].style.display = "block";
    document.getElementsByClassName("manipula-norma-maturidade")[0].style.display = "none";
}

function handlePerfil(){
    document.getElementsByClassName("manipula-norma-maturidade")[0].style.display = "block";
    document.getElementsByClassName("manipula-norma-gestao")[0].style.display = "none";
}

function Norma() {

      

    return (
        <div>
            <div>
                <button className="button-norma" onClick={handleNorma}>Norma de Maturidade de Gestão</button>
                <button className="button-norma" onClick={handlePerfil}>Perfil de Maturidade</button>
            </div>

            <div className="manipula-norma-gestao">
                <p className="p-title-info"><b>Norma de Maturidade de Gestão</b></p>

                <p className="text-p-info">Data: 3/2/2009</p>

                <p className="p-title-info-2">Objetivo e Aplicação</p>

                <p className="text-p-info">O objetivo da Norma é promover o contínuo aprimoramento das empresas
                 do setor de Relacionamento com Clientes e Consumidores, por meio do incremento
             constante da qualidade, decorrente da utilização dos procedimentos e boas práticas inseridas nesta Norma.</p>

                <p className="text-p-info"> Esta Norma é aplicável às Centrais de Relacionamento, também chamadas de
                 Call Center, Contact Center, Help Desk, SAC (Serviço de Atendimento ao Consumidor) e/ou Telemarketing,
                 próprias ou terceirizadas, que executam atividades de atendimento ativo e receptivo, por meio de
              telefone, e-mail, Chat, fax, cartas para os seguintes serviços, dentre outros</p>

                <p className="text-p-info">
                    <li>Atendimento e relacionamento com o Consumidor (marketing de relacionamento</li>
                    <li>Consultas de pré-venda, venda e pós-venda;</li>
                    <li>Suporte técnico;</li>
                    <li>Pesquisas diversas;</li>
                    <li>Recuperação de Consumidores;</li>
                    <li>Serviço de Atendimento ao Consumidor (sugestões, reclamações, acionamento e agendamento de serviços, pedidos, informações etc.);</li>
                    <li>Processamento de Transações (pagamentos, transferências, desbloqueio de cheques, abertura e ativação de contas etc);</li>
                    <li>Avaliação da percepção do Consumidor sobre produtos e serviços;</li>
                    <li>Ouvidoria</li>
                </p>
                <a target="_blank" href="/docs/NormaMaturidadeVersao2.pdf" rel="noopener noreferrer">
                <p className="text-p-info">Faça o download do arquivo clicando aqui.</p>
                </a>

                <p className="text-p-info">Autor: <b>PROBARE</b></p>

            </div>

            <div className="manipula-norma-maturidade">

                <p className="p-title-info"><b>Perfil de Maturidade</b></p>

                <p className="text-p-info">Data: 3/2/2009</p>

                <p className="p-title-info-2">Objetivo e Aplicação</p>

                <p className="text-p-info">O Perfil de Maturidade atesta o grau de implementação que a organização
                possui em relação aos requisitos da Norma de Maturidade de Gestão, podendo ser pleiteado por qualquer
                 Central de Relacionamento em operação, independentemente se presta serviços a vários contratantes ou se
                  está incorporada a uma empresa (Central de Relacionamento própria).</p>

                <p className="text-p-info"> As condições e o regulamento para o Perfil de Maturidade estão contidos no 
                     documento Regulamento do PROBARE: Para fazer o download do <b>Regulamento do PROBARE</b> <a target="_blank" href="/docs/RegulamentoV3.pdf" rel="noopener noreferrer">clique aqui</a> – Versão 3 Oficial.</p>

                <p className="text-p-info">
                    Para fazer o download do <b>Guia de Avaliação da Norma de Auto-Regulamentação do Setor de 
                    Relacionamento</b> <a target="_blank" href="/docs/GuiaAvaliacaoV5.pdf" rel="noopener noreferrer">clique aqui</a> - Versão 5 Oficial.
                </p>

                <p className="text-p-info">Para fazer o download da <b>Norma de Maturidade de Gestão do 
                    PROBARE</b> <a target="_blank" href="/docs/NormaMaturidadeVersao2.pdf" rel="noopener noreferrer">clique aqui</a> - Versão 2 Oficial.

</p>

                <p className="text-p-info"><b>Para processo de obtenção do Perfil de Maturidade será seguido o fluxo abaixo:</b></p>

                <p className="text-p-info">A empresa preenche o Termo de Adesão e envia para a Gerenciadora. <b>Termo de Adesão</b>  – <a target="_blank" href="/docs/TermoAdesaoEmpresasV10.doc" rel="noopener noreferrer">clique aqui</a></p>

                <p className="text-p-info">A Gerenciadora confirma o recebimento do Termo de Adesão à empresa e envia aos Organismos de Auditoria, homologados no Probare, indicados pela empresa, para emissão de proposta.</p>
                <p className="text-p-info">O(s) Organismo(s) de Auditoria entra(m) em contato com a empresa, para negociação direta sobre o valor da auditoria.</p>
                <p className="text-p-info">A empresa contrata um dos Organismos de Auditoria.</p>

                <p className="text-p-info">O Organismo de Auditoria escolhido realiza a auditoria e emite Relatório com parecer.</p>

                <p className="text-p-info">O Comitê Executivo, com base no parecer do Organismo de Auditoria, poderá conceder o Perfil de Maturidade.</p>

                <p className="text-p-info">Após a confirmação da certificação do Perfil de Maturidade, a Gerenciadora informa a empresa sobre o procedimento para pagamento da taxa anual do Probare no valor de R$ 3.000,00.</p>

                <p className="text-p-info">A empresa efetua o pagamento da taxa anual à Gerenciadora do Probare.</p>

                <p className="text-p-info">A Gerenciadora envia à empresa o logotipo do Perfil de Maturidade por e-mail e o Certificado pelo correio.</p>

                <div className="gerenciadores">
                    <span><u>Gerenciadora:</u></span></div>
                <div className="gerenciadores">
                    <span><b>IdEQ Treinamento e Assessoria Empresarial Ltda.</b></span>
                    <span>Contato: Cesar Ken Mori</span>
                    <span>Fone: (11) 5594 - 7442</span>
                    <span>e-mail: ideqdir@terra.com.br</span>
                </div>
                <div className="gerenciadores"><span><u>Organismos de Auditoria Homologados</u></span></div>
                <div className="gerenciadores">
                <span><b>ABS Quality Evaluations, Inc.</b></span>
                <span>Contato: Fernando Pimenta</span>
                <span>Fone: + (55) 11 2595-4216</span>
                <span>Celular: + (55) 11 98115-2510</span>
                <span>e-mail: fpimenta@abs-qe.com</span>
                <span>site: <a target="_blank" href="http://www.abs-qe.com/pt/" rel="noopener noreferrer">www.abs-qe.com</a></span>

                </div>
                <div className="gerenciadores">
                    <span><b>BSI Brasil Sistemas de Gestão Ltda.</b></span>
                    <span>Contato: Leonardo Soares Fernandes</span>
                    <span>Fone: (11) 2148 - 9600</span>
                    <span>Fax: (11) 2148 - 9601</span>
                    <span>e-mail: leonardo.fernandes@bsigroup.com</span>
                    <span>site: <a target="_blank" href="https://www.bsigroup.com/pt-BR" rel="noopener noreferrer">www.bsigroup.com/pt-BR</a></span>
                </div>

                <div className="gerenciadores">
                    <span><b>BVQI - Bureau Veritas Certification</b></span>
                    <span>Contato: Amauri Ávila</span>
                    <span>Fone: (11) 2655 - 9001</span>
                    <span>Fax: (11) 5070 - 9810</span>
                    <span>e-mail: amauri.avila@br.bureauveritas.com</span>
                    <span>site: <a target="_blank" href="http://www.bvqi.com.br" rel="noopener noreferrer">www.bvqi.com.br</a></span>
                </div>
                <div className="gerenciadores">
                    <span><b>DQS do Brasil Ltda.</b></span>
                    <span>Contato: Alexandre Oliveira</span>
                    <span>Fone: (11) 5696 – 5928</span>
                    <span>Fax: (11) 5696 – 5940</span>
                    <span>e-mail: alexandre.oliveira@dqs.com.br</span>
                    <span>e-mail: dqs@dqs.com.br</span>
                    <span>site: <a target="_blank" href="https://www.dqs.com.br" rel="noopener noreferrer">www.dqs.com.br</a></span>
                </div>
                <div className="gerenciadores" style={{ marginBottom: 20 }}>
                    <span><b>Fundação Vanzolini - FCAV</b></span>
                    <span>Contato: Simone Custódio</span>
                    <span>Fone: (11) 3913 - 7100 ramal 129</span>
                    <span>Fax: (11) 3832 - 2070</span>
                    <span>e-mail: certific@vanzolinicert.org.br</span>
                    <span>site: <a target="_blank" href="https://www.vanzolini.org.br" rel="noopener noreferrer">www.vanzolini.org.br</a></span>
                </div>


            </div>

        </div>
    )
}

export default Norma;