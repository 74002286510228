import React from 'react';
import './index.css';
import Probare from './component/ouvidoria/index';
import Violacao from './component/violacao/index'
import Main from './component/pages/index'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Ticket from './component/ticket/ticket';

function App() {
  return (
    <Router >
      <Route exact path="/" component={Main} />
      <Route exact path="/ticket" component={Ticket} />
      <div className="app-header">
        <Switch>
          <Route exact path="/main" component={Probare} />
          <Route exact path="/ouvidoria" component={Violacao} />

        </Switch>
      </div>
    </Router>
  );
}

export default App;
