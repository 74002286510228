import React from 'react';


function Estatisticas() {
    return (
        <div>
                <p className="p-title-info">Estatísticas</p>
        </div>
    )
}

export default Estatisticas;