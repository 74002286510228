import React, { Component } from "react";
import "./style.css";
import Logo from '../../img/logo_probare.svg'
import Abemp from '../../img/abemp.png'
import Abrarec from '../../img/abrarec.png'
import Abt from '../../img/abt.png'
import { Link } from 'react-router-dom'

import { getTicket } from '../../service/violacao'

import banner from '../../img/banner.png'
import wpp from '../../img/wpp.svg'

// Pages
import Probare from './probare'
import Codigo from './codigo'
import Sobre from './sobre'
import EmpresaCertificado from './empresaCertificado'
import Selo from './selo'
import Norma from './norma'
import Estatistica from './estatisticas'
import Ticket from '../ticket/ticket'

class main extends Component {

    state = {
        currentPage: 'probare',
        valueSearch: '',
        ticket: null,
        hamburger: false,
    }

    handlePage = (currentPage) => {
        let closeHamb = false;
        if (window.screen.width < 500) {
            closeHamb = !this.state.hamburger
        }
        this.setState({
            currentPage,
            hamburger: closeHamb
        });
    }

    openHamb = () => {
        this.setState({
            hamburger: !this.state.hamburger
        })
    }


    search = () => {

        const { valueSearch } = this.state;

        this.setState({ ticket: null });

        getTicket(valueSearch).then((ticket) => {
            this.setState({ ticket });
        });
        this.setState({ currentPage: 'ticket' });
        console.log(this.state)

    }

    render() {
        const { currentPage, ticket, hamburger } = this.state;
        return (
            <div >

                <div className="hamburger-header" onClick={this.openHamb}>
                    <i className="material-icons icon-list" >
                        list
                    </i>
                </div>

                <div className="header-pages">
                    <div className="manipula-img">
                        <img src={Logo} alt="Logo" className="img-logo"></img>
                    </div>
                    <div className="header-title-page">
                        <p className="subtitle-programa">
                            <b>PROGRAMA BRASILEIRO DE AUTO-REGULAMENTAÇÃO</b>
                            <span style={{ fontSize: 13 }}> DO SETOR DE RELACIONAMENTO (CALL CENTER / CONTACT CENTER HELP DESK / SAC / TELEMARKETING)</span>
                        </p>
                    </div>
                    <div className="imagens">
                        <a href="http://www.abemd.org.br/" target="_blank" rel="noopener noreferrer"><img src={Abemp} alt="Logo" className="img-logo-1 img-log-sqr"></img></a>
                        <a href="http://www.abrarec.com.br/" target="_blank" rel="noopener noreferrer"><img src={Abrarec} alt="Logo" className="img-logo-1"></img></a>
                        <a href="http://www.abt.org.br/" target="_blank" rel="noopener noreferrer"><img src={Abt} alt="Logo" className="img-logo-1"></img></a>
                    </div>
                </div>
                <div className="barra-verde">

                    <Link to="/ouvidoria" className="link-ouvidoria" >
                        <button className="button-fale" onClick={this.handleOuvi}><b>FALE COM A OUVIDORIA</b></button>
                    </Link>
                    <div className="wrapper-input">
                        <input className="input-search" placeholder="Consulte sua denúncia pelo protocolo" onChange={(e) => { this.setState({ valueSearch: e.target.value }) }}></input>
                        <button className="button-fale" onClick={this.search}>Buscar</button>

                    </div>
                    <a href="https://portal.probare.org.br" rel="noopener noreferrer">
                        <button className="button-acessar">Acessar</button>
                    </a>
                </div>
                <div className="info">
                    <div className="title-all-info web">
                        <div className="text-link-all-info" style={{ marginTop: 20 }}><p className="p-text-all">
                            <button className="button-back" onClick={() => this.handlePage('probare')}><b>PROBARE</b></button></p>
                        </div>
                        <div className="text-link-all-info"><p className="p-text-all">
                            <button className="button-back" onClick={() => this.handlePage('codigo')} ><b>CÓDIGO DE ÉTICA</b></button></p>
                        </div>
                        <div className="text-link-all-info"><p className="p-text-all">
                            <button className="button-back" onClick={() => this.handlePage('sobre')}><b>SOBRE A OUVIDORIA</b></button></p>
                        </div>
                        <div className="text-link-all-info"><p className="p-text-all">
                            <button className="button-back" onClick={() => this.handlePage('selo')}><b>SELO DE ÉTICA</b></button></p>
                        </div>
                        <div className="text-link-all-info"><p className="p-text-all">
                            <button className="button-back" onClick={() => this.handlePage('norma')}><b>NORMA DE MATURIDADE DE GESTÃO</b></button></p>
                        </div>
                        <div className="text-link-all-info"><p className="p-text-all">
                            <Link to="/ouvidoria">
                                <button className="button-back"><b>FALE COM A OUVIDORIA</b></button>
                            </Link>
                        </p>
                        </div>
                        <div className="text-link-all-info"><p className="p-text-all" style={{ width: '105%' }}>
                            <button className="button-back" onClick={() => this.handlePage('empresa')}><b>EMPRESAS CERTIFICADAS</b></button></p>
                        </div>

                        <div className="banner-wpp">
                            <div className="wpp-txt">
                                <img src={wpp} alt="WhatsApp" />
                                <p className="p-wpp">FALE COM A OUVIDORIA NO WHATSAPP</p>
                            </div>
                            <div>
                                <a target="_blank" href="https://wa.me/551149493310?text=Ol%C3%A1" rel="noopener noreferrer">
                                    <button className="btn-wpp"><b>CLIQUE AQUI</b></button>
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* MOBILEEEE */}
                    {hamburger === true &&
                        <div className="title-all-info">

                            <div className="text-link-all-info" style={{ marginTop: 20 }}><p className="p-text-all">
                                <button className="button-back" onClick={() => this.handlePage('probare')}><b>PROBARE</b></button></p>
                            </div>
                            <div className="text-link-all-info"><p className="p-text-all">
                                <button className="button-back" onClick={() => this.handlePage('codigo')}><b>CÓDIGO DE ÉTICA</b></button></p>
                            </div>
                            <div className="text-link-all-info"><p className="p-text-all">
                                <button className="button-back" onClick={() => this.handlePage('sobre')}><b>SOBRE A OUVIDORIA</b></button></p>
                            </div>
                            <div className="text-link-all-info"><p className="p-text-all">
                                <button className="button-back" onClick={() => this.handlePage('selo')}><b>SELO DE ÉTICA</b></button></p>
                            </div>
                            <div className="text-link-all-info"><p className="p-text-all">
                                <button className="button-back" onClick={() => this.handlePage('norma')}><b>NORMA DE MATURIDADE</b></button></p>
                            </div>
                            <div className="text-link-all-info"><p className="p-text-all">
                                <Link to="/ouvidoria">
                                    <button className="button-back"><b>FALE COM A OUVIDORIA</b></button>
                                </Link>
                            </p>
                            </div>
                            <div className="text-link-all-info"><p className="p-text-all" style={{ width: '105%' }}>
                                <button className="button-back" onClick={() => this.handlePage('empresa')}><b>EMPRESAS CERTIFICADAS</b></button></p>
                            </div>

                            <div className="banner-wpp-mobile">
                                <img src={banner} className="banner-mobile" alt="WhatsApp" />
                                <div className="wpp-txt-mobile">
                                    <img src={wpp} className="wpp-img" alt="WhatsApp" />
                                    <p className="p-wpp-mobile">FALE COM A OUVIDORIA NO WHATSAPP</p>
                                </div>
                                <div>
                                    <a target="_blank" href="https://wa.me/551149493310?text=Ol%C3%A1" rel="noopener noreferrer">
                                        <button className="btn-wpp-mobile"><b>CLIQUE AQUI</b></button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="info-all">

                        { currentPage === 'ticket' && this.state.ticket === null ?
                            <div style={{marginTop: 30}}>Número de protocolo inválido. Por favor, verifique os dados informados e tente novamente. </div>: ''
                        }
                        {currentPage === 'probare' &&
                            <div className="dis-probare">
                                <Probare className="dis-probare" />

                            </div>
                        }
                        {currentPage === 'codigo' &&
                            <div className="dis-codigo">
                                <Codigo />
                            </div>
                        }
                        {currentPage === 'sobre' &&
                            <div className="dis-sobre">
                                <Sobre />
                            </div>
                        }
                        {currentPage === 'empresa' &&
                            <div className="dis-empresa">
                                <EmpresaCertificado />
                            </div>
                        }
                        {currentPage === 'selo' &&
                            <div className="dis-selo">
                                <Selo />
                            </div>
                        }
                        {currentPage === 'norma' &&
                            <div className="dis-norma">
                                <Norma />
                            </div>
                        }
                        {currentPage === 'estatistica' &&
                            <div className="dis-estatistica">
                                <Estatistica />
                            </div>
                        }
                        {(currentPage === 'ticket' && !!ticket) &&
                            <div>
                                <Ticket ticket={ticket} />
                            </div>
                        }


                    </div>
                </div>
            </div>
        );
    }
}

export default main;
