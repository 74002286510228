import React from 'react';


function Codigo() {
    return (
        <div>
            <p className="p-title-info"><b>CÓDIGO DE ÉTICA</b></p>

            <p className="text-p-info">O Código de Ética é uma iniciativa das três entidades representantes do
             mercado de Relacionamento com Clientes e Consumidores no país: ABEMD, ABRAREC e ABT, que identificaram a
             necessidade das definições de parâmetros de auto-regulamentação no segmento, de forma a consolidar e
             aprimorar o atendimento aos Consumidores e aos clientes contratantes.
             Este Código de Ética está em conformidade com a legislação vigente e o Código de Defesa do Consumidor.</p>
             
             <p className="text-p-info">Para fazer o download do Código de Ética do PROBARE, vigente a partir de 21 de novembro de 
                 2019, <a href="/docs/Codigo_de_Etica_Probare_Revisao_de_Outubro_de_2019.pdf" target="_blank" rel="noopener noreferrer">clique aqui</a> (Novo Código de Ética do Probare – Versão de outubro de 2019)</p>

             <p className="text-p-info">Para ter acesso a versão anterior do Código de Ética, vigente até 21 de novembro de 
                 2019, <a href="/docs/Codigo_de_Etica_Revisao_4_OFICIAL.pdf" target="_blank" rel="noopener noreferrer">clique aqui</a> (Versão 4).</p>
            
             <p className="text-p-info">Empresas que tenham que venham a aderir ao Probare ou renovar a sua adesão 
                a partir de 21 de novembro de 2019, irão aderir necessariamente ao Novo Código de Ética do Probare, 
                versão de outubro de 2019. Durante o processo de desenvolvimento do Código de Ética e das suas revisões, 
                foram envolvidas aproximadamente 70 empresas, dentre elas, prestadores de serviços, 
                fornecedores e contratantes. Por meio de um processo transparente e participativo, 
                essas empresas tiveram a oportunidade de revisar as versões preliminares e sugerir melhorias, 
                tornando o conteúdo do Código de Ética alinhado à realidade do mercado e às necessidades de 
                todas as partes interessadas.
            </p>

            <p className="text-p-info">Durante o processo de desenvolvimento do Código de Ética, foram envolvidas
             aproximadamente 70 empresas, dentre elas, prestadores de serviços, fornecedores e contratantes.
             Por meio de um processo transparente e participativo, essas empresas tiveram a oportunidade de
             revisar as versões preliminares e sugerir melhorias, tornando o conteúdo do
             Código de Ética alinhado à realidade do mercado e às necessidades de todas as partes interessadas.</p>

            <p className="text-p-info"> A responsabilidade pela observância das normas de conduta neste Código cabe à
             Empresa/Contratante e sua Central de Relacionamento (própria ou terceirizada). </p>

            <p className="text-p-info">Este Código de Ética é aplicável às Centrais de Relacionamento,
            também chamadas de Call Center, Contact Center, Help Desk, SAC – Serviço de Atendimento ao
             Consumidor e/ouTelemarketing, próprias ou terceirizadas, que executam atividades de atendimento
              ativo e/ou receptivo, para Consumidores (pessoas físicas e jurídicas),
             por meio de telefone, e-mail, Chat, fax, cartas para, dentre outros, os seguintes serviços:</p>


            <p className="text-p-info">

                <li>Atendimento e relacionamento com o Consumidor (pessoas físicas e jurídicas) - marketing de relacionamento;</li>
                <li>Consultas de pré-vendas e pós-vendas;</li>
                <li>Suporte técnico;</li>
                <li>Pesquisa;</li>
                <li>Recuperação de Consumidores;</li>
                <li>Serviço de Atendimento ao Consumidor (sugestões, reclamações, acionamento e agendamento de serviços, pedidos, informações.);</li>
                <li>Processamento de Transações (pagamentos, transferências, desbloqueio de cheques, abertura e ativação de contas.);</li>
                <li>Avaliação da percepção do Consumidor sobre produtos e serviços;</li>
                <li>Ouvidoria.</li>
            </p>

        </div>
    )
}

export default Codigo;