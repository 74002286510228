import React, { Component } from "react";
import "./styleViolacao.css";
import Logo from '../../img/logo_probare.svg'
import Input from '@material-ui/core/Input';
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom';
import { testaCPF } from '../../helpers/validate.helpers'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import saveViolacao from '../../service/violacao';

import ptBR from 'date-fns/locale/pt-BR';

const dataY = new Date();

class Perguntas extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isdisabled1: true,
            isdisabled2: true,
            ischeck: false,
            reclamacao: '',

            validateInput: false,

            dados: {
                nome: '',
                cpf: '',
                email: '',
                telefoneUsuario: '',
                telefoneEfetuou: '',
                empresa: '',
                data: '',
                objetivo: '',
                violacao: '',
                autoriza_uso_dados: false,
                status: 'em aberto',
            },

        }
        this.handleChange = this.handleChange.bind(this);
    }


    validate = () => {
        let nomeError = "";
        let emailError = "";

        if (!testaCPF(this.state.dados.cpf.replace(/[^0-9]/ig, ''))) {
            nomeError = "CPF inválido";
        }

        if (!this.state.dados.nome) {
            nomeError = "Nome inválido";
        }

        if (!this.state.dados.email.includes("@") || !this.state.dados.email.split('@')[1].includes(".")) {
            nomeError = "Email inválido"
        }

        if (!this.state.dados.telefoneUsuario) {
            nomeError = "Telefone inválido";
        }

        if (!this.state.dados.telefoneEfetuou) {
            emailError = "Telefone inválido";
        }
        if (!this.state.dados.empresa) {
            emailError = "empresa inválido";
        }

        if (this.state.dados.objetivo === '') {
            emailError = "Objetivo inválido";
        }
        if (this.state.dados.violacao === '') {
            emailError = "Violacao inválido";
        }

        if (emailError || nomeError) {
            this.setState({ emailError, nomeError });
            return false;
        }

        return true;
    };


    checked = () => {

        this.setState({
            ischeck: !this.state.ischeck
        })
        console.log(this.state.ischeck)
    }

    handleChange = (date) => {
        this.setState({
            dados: {
                ...this.state.dados,
                data: date
            }

        })
    }

    onChange = (e) => {
        const { name, value } = e.target;
        let { dados } = this.state;
        dados[name] = value;
        this.setState({ dados });
    }

    onChangeObjetivo = (e) => {
        this.setState({
            objetivo: e.target.value
        })
    }

    onChangeViolacao = (e) => {
        this.setState({
            violacao: e.target.value
        })
    }

    onDisabledObj = async () => {
        await this.setState({
            objetivo: ''
        })
        this.setState({
            isdisabled1: false
        })
    }

    onDisabledVio = async () => {
        await this.setState({
            violacao: ''
        })
        this.setState({
            isdisabled2: false
        })
    }

    handleOptionChangeObj = (e) => {
        const { name, value } = e.target;
        let { dados } = this.state;
        dados[name] = value;
        this.setState({ dados });
        this.setState({ objetivo: e.target.value });
        this.setState({ isdisabled1: true })
    }

    handleOptionChangeVio = (e) => {
        const { name, value } = e.target;
        let { dados } = this.state;
        dados[name] = value;
        this.setState({ dados });
        this.setState({ violacao: e.target.value });
        this.setState({ isdisabled2: true })
    }

    handleFormSubmit = async (formSubmitEvent) => {
        formSubmitEvent.preventDefault();
        const isValid = this.validate();
        const { dados } = this.state;
        dados.violacao = this.state.violacao;
        dados.objetivo = this.state.objetivo;

        this.setState({
            validateInput: true
        })
        if (isValid) {
            this.setState({
                validateInput: false
            })
            try {
                const response = await saveViolacao(dados);

                this.setState({
                    reclamacao: response.data.result
                })

                this.setState({
                    dados: {
                        nome: '',
                        email: '',
                        telefoneUsuario: '',
                        telefoneEfetuou: '',
                        empresa: '',
                        objetivo: '',
                        violacao: '',
                        data: '',
                    }

                })
                document.getElementsByClassName("manipula-id")[0].style.display = "block";
                document.getElementsByClassName("manipula-info")[0].style.display = "none";
                document.getElementsByClassName("error-input")[0].style.display = "none";
            }
            catch (e) {

            }
        } else {
            document.getElementsByClassName("error-input")[0].style.display = "block";
        }

    }
        ;

    handleManipula = () => {

        document.getElementsByClassName("manipula-id")[0].style.display = "none";
        document.getElementsByClassName("manipula-info")[0].style.display = "block";
    }
    render() {
        const { dados, validateInput } = this.state;

        return (
            <div>
                <div className="title">
                    <Link to="/">
                        <div className="manipula-img">
                            <img src={Logo} alt="Logo" className="img-logo"></img>
                        </div>
                    </Link>
                    <div className="header-title">
                        <span className="subtitle-1">
                            <b>Ouvidoria do Programa de Auto-Regulamentação</b>
                        </span>
                        <span className="subtitle-2">
                            <b>NOTICIA DE VIOLAÇÃO AO CÓDIGO DE ÉTICA</b>
                        </span>
                    </div>
                </div>



                <div className="manipula-id">
                    <span className="subTitle-perguntas-manipula"><p><b>Obrigado. A sua denúncia foi recebida.</b></p></span>
                    <div className="sb-manipula">
                        <span className="subTitle-perguntas-manipula-sb"><p>Anote o seu protocolo, com ele você pode voltar aqui a qualquer momento para acompanhar o andamento do seu processo.</p></span>
                    </div>
                    <div className="id-atendimento">
                        <p className="p-objetivo" style={{ marginBottom: 0 }}><b>Seu protocolo de atendimento é esse:</b></p>
                        <span>
                            <p className="id">{this.state.reclamacao.id_atendimento}</p>
                        </span>
                    </div>


                    <div>
                        <button className="button-manipula" onClick={this.handleManipula} type="submit">Nova Reclamação</button>
                    </div>
                </div>


                <div className="manipula-info">
                    <div className="corpo">
                        <span className="subTitle-perguntas"><p><b>Conte aqui sobre a violação ao Código de Ética do PROBARE</b></p></span>


                        <p className="p-objetivo"><b>Deixe seu contato aqui.</b></p>
                        <form className="ligacao-probare" onSubmit={this.handleFormSubmit}>
                            <div className="inputs-violacao">

                                <div className="input-date-violacao">
                                    <div className="sub-input-violacao">
                                        <span className="label-input-violacao"><b>Nome:</b></span>
                                        <Input
                                            className="sub-input-all-violacao-contato"
                                            name="nome"
                                            placeholder="Preencha aqui"
                                            value={dados.nome || ''}
                                            onChange={this.onChange}
                                        />
                                        {validateInput === true && dados.nome === '' ? <span className="error-tp">Nome inválido</span> : ''}
                                    </div>
                                    <div className="sub-input-violacao" style={{ marginLeft: 20 }}>
                                        <span className="label-input-violacao" style={{ marginLeft: 10 }}><b>Documento(CPF):</b></span>
                                        <InputMask
                                            mask="999.999.999-99"
                                            maskChar= ''
                                            className="input-all-violacao-mask-contato"
                                            name="cpf"
                                            placeholder="Preencha aqui"
                                            value={dados.cpf || ''}
                                            onChange={this.onChange}
                                        />
                                        {validateInput === true && !testaCPF(this.state.dados.cpf.replace(/[^0-9]/ig, '')) ? <span className="error-tp" style={{ marginLeft: 10 }}>CPF inválido</span> : ''}
                                    </div>
                                </div>
                                <div className="input-date-violacao mt-10">
                                    <div className="sub-input-violacao">
                                        <span className="label-input-violacao"><b>Email:</b></span>
                                        <Input
                                            className="sub-input-all-violacao-contato"
                                            name="email"
                                            placeholder="Preencha aqui"
                                            value={dados.email || ''}
                                            onChange={this.onChange}
                                        />
                                        {validateInput === true && !this.state.dados.email.includes(".") ? <span className="error-tp">Email inválido</span> : ''}
                                    </div>
                                    <div className="sub-input-violacao" style={{ marginLeft: 20 }}>
                                        <span className="label-input-violacao" style={{ marginLeft: 10 }}><b>Telefone:</b></span>

                                        <InputMask
                                            mask="(99) 99999-9999"
                                            className="input-all-violacao-mask-contato"
                                            maskChar= ''
                                            name="telefoneUsuario"
                                            placeholder="(00) 0000 0000"
                                            value={dados.telefoneUsuario || ''}
                                            onChange={this.onChange}
                                        />
                                        {validateInput === true && dados.telefoneUsuario === '' ? <span className="error-tp" style={{ marginLeft: 10 }}>Telefone inválido</span> : ''}
                                    </div>
                                </div>



                            </div>

                        </form>

                        <p className="p-objetivo"><b>Objetivo da ligação que violou o Código de Ética do PROBARE:</b></p>
                        <form className="ligacao-probare" onSubmit={this.handleFormSubmit}>


                            <div className="ob">
                                <label className="container" htmlFor="radioOferta">
                                    <input type="radio" id="radioOferta" value={"oferta"} name="objetivo" onChange={this.handleOptionChangeObj} checked={this.state.objetivo === "oferta"}></input>
                                    <span className="checkmark"></span>
                                    <p className="text-div" >Oferta de Produto/Serviço</p>
                                </label>


                            </div>

                            <div className="ob">
                                <label className="container">
                                    <input type="radio" value={"cobranca"} name="objetivo" onChange={this.handleOptionChangeObj} checked={this.state.objetivo === "cobranca"}></input>
                                    <span className="checkmark"></span>
                                    <p className="text-div">Cobrança de Valores*</p>
                                </label>

                            </div>

                            <div className="ob-white">
                                <p className="text-div-white">* Atividade não regulamentada pelo Código de Ética. Contudo,
                                persistindo a intenção de encaminhar a Reclamação sobre o tema, a manteremos registrada.</p>
                            </div>

                            <div className="ob">
                                <label className="container">
                                    <input type="radio" onChange={this.onDisabledObj} value={"outra"} name="objetivo"></input>
                                    <span className="checkmark"></span>
                                    <p className="text-div">Outra questão</p>
                                </label>

                            </div>

                            {!this.state.isdisabled1 ?
                                <div className="ob-input">
                                    <input type="text" name="objetivo" className="input-bo" disabled={this.state.isdisabled1} onChange={this.onChangeObjetivo} placeholder="Preencha aqui" value={this.state.objetivo}></input>
                                </div>
                                :
                                null
                            }
                        </form>
                        {validateInput === true && this.state.isdisabled1 === true ? <span className="error">Escolha uma opção</span> : ''}
                        {validateInput === true && this.state.isdisabled1 === false ? <span className="error">Por favor, indique o motivo da ligação</span> : ''}

                        <p className="p-objetivo"><b>Qual foi a violação ao Código de Ética?</b></p>
                        <form className="ligacao-probare" onSubmit={this.handleFormSubmit}>
                            <div className="ob">
                                <label className="container">
                                    <input type="radio" name="violacao" value={'a13'} onChange={this.handleOptionChangeVio} checked={this.state.violacao === "a13"}></input>
                                    <span className="checkmark"></span>
                                    <p className="text-div"><b>Artigo 13</b> – Problemas com a remoção dos dados da lista de contatos ativos</p>
                                </label>

                            </div>

                            <div className="ob">
                                <label className="container">
                                    <input type="radio" name="violacao" value={'a15'} onChange={this.handleOptionChangeVio} checked={this.state.violacao === "a15"}></input>
                                    <span className="checkmark mt" ></span>
                                    <p className="text-div"><b>Artigo 15</b> – Contato fora do horário permitido (de segunda a sexta-feira, das 9h às 21h, e aos sábados, das 10h às 16h).</p>
                                </label>
                            </div>

                            <div className="ob">
                                <label className="container">
                                    <input type="radio" name="violacao" value={'a15p3'} onChange={this.handleOptionChangeVio} checked={this.state.violacao === "a15p3"} ></input>
                                    <span className="checkmark mt"></span>
                                    <p className="text-div"><b>Artigo 15, parágrafo 3º </b>– Realização de novo contato para oferta do mesmo produto/serviço dentro do prazo de 60 (sessenta) dias após a recusa.</p>
                                </label>
                            </div>
                            <div className="ob">
                                <label className="container">
                                    <input type="radio" name="violacao" value={'a15p4'} onChange={this.handleOptionChangeVio} checked={this.state.violacao === "a15p4"}></input>
                                    <span className="checkmark"></span>
                                    <p className="text-div"><b>Artigo 15, parágrafo 4º</b> – Realização de mais de 3 (três) chamadas telefônicas no mesmo dia.</p>

                                </label>
                            </div>
                            <div className="ob">
                                <label className="container">
                                    <input type="radio" name="violacao" value={'a15p5'} onChange={this.handleOptionChangeVio} checked={this.state.violacao === "a15p5"}></input>
                                    <span className="checkmark mt"></span>
                                    <p className="text-div"><b>Artigo 15, parágrafo 5º </b>– Realização de nova oferta de produto/serviço dentro do prazo de 6 (seis) meses após o cancelamento do produto/serviço.</p>
                                </label>
                            </div>

                            <div className="ob">
                                <label className="container">
                                    <input type="radio" name="violacao" onChange={this.onDisabledVio}></input>
                                    <span className="checkmark"></span>
                                    <p className="text-div"><b>Outra violação</b></p>
                                </label>

                            </div>
                            {!this.state.isdisabled2 ?
                                <div className="ob-input">
                                    <input type="text" className="input-bo" placeholder="Preencha aqui o artigo e parágrafo violado" onChange={this.onChangeViolacao} value={this.state.violacao}></input>
                                </div>
                                :
                                null
                            }
                        </form>
                        {validateInput === true && this.state.isdisabled2 === true ? <span className="error">Escolha uma opção</span> : ''}
                        {validateInput === true && this.state.isdisabled2 === false ? <span className="error">Por favor, indique a violação ocorrida</span> : ''}
                        <p className="p-duvidas">Dúvidas? Conheça o nosso Código de Ética.</p>
                        <form className="ligacao-probare" onSubmit={this.handleFormSubmit}>
                            <div className="inputs-violacao">
                                <span className="label-input-violacao"><b>Número do Telefone que Efetuou a Ligação:</b></span>
                                <InputMask
                                    mask="(99) 99999-9999"
                                    className="input-all-violacao-mask"
                                    name="telefoneEfetuou"
                                    placeholder="(00) 0000 0000"
                                    value={dados.telefoneEfetuou || ''}
                                    onChange={this.onChange}
                                />
                                {validateInput === true && dados.telefoneEfetuou === '' ? <span className="error">Telefone inválido</span> : ''}
                                <span className="label-input-violacao"><b>Em nome de qual empresa você foi contatado:</b></span>
                                <Input
                                    className="input-all-violacao"
                                    name="empresa"
                                    placeholder="Preencha aqui"
                                    value={dados.empresa || ''}
                                    onChange={this.onChange}
                                />
                                {validateInput === true && dados.empresa === '' ? <span className="error">Nome de empresa inválido</span> : ''}



                                <div className="sub-input-violacao">
                                    <span className="label-input-violacao"><b>Quando você foi contatado:</b></span>
                                    <DatePicker
                                        locale={ptBR}
                                        className="sub-input-all-violacao"
                                        placeholder="Preencha aqui"
                                        selected={this.state.dados.data}
                                        onChange={this.handleChange}
                                        minDate={new Date().setDate(dataY.getDate() - 90)}
                                        maxDate={new Date().setFullYear(dataY.getFullYear() - 0)}
                                        showTimeSelect
                                        timeFormat="p"
                                        timeIntervals={15}
                                        dateFormat="Pp"
                                        format={'dd/MM/yyyy'}

                                    />
                                    {validateInput === true && dados.data === '' ? <span className="error">Data inválida</span> : ''}
                                </div>
                            </div>

                            <p className="p-observacao">Observação: A data indicada deve ser no máximo a 90 dias, conforme regra do Código de Ética </p>

                            <span className="p-observacao">Observação: A indicação de dia e horário facilita a apuração da violação do Código de Ética</span>
                            <div className="local-check">
                                <input type="checkbox" onClick={this.checked} checked={this.state.checked}></input>
                                <span className="p-check">Eu autorizo o uso dos meus dados pessoais e os demais dados que informei para a Ouvidoria do Probare, autorizando ainda o compartilhamento destes dados com as empresas aderentes ao Probare a para instâncias de Governo e entidades integrantes do Sistema Nacional de Defesa do Consumidor com a finalidade de apurar violação ao Código de Ética do Probare e de regras aplicáveis às relações de consumo, até o momento de finalização da apuração da violação.</span>
                            </div>
                        </form>

                        <div className="dis-mob">
                            <span className="error-input">{"Preencha os campos corretamente!"}</span>
                            <button className="button-reclamacao" onClick={this.handleFormSubmit} type="submit">Encaminhar Reclamação</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default Perguntas;
