const axios = require('axios');
const config = require('../config/index');

function saveViolacao(dados) {
    return axios.post(`${config.url}/reclamacao`, dados).then(response => response).catch(err => err)
}


export function getTicket(id_atendimento){
    return axios.get(`${config.url}/reclamacao/${id_atendimento}`).then(response => response.data).catch(err => {console.log(err)
    return null;})
}

export default saveViolacao;
