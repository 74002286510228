import React from 'react';

function EmpresaCertificado() {
    return (
        <div className="font-mobile">
            <p className="p-title-info"><b>
                Selo de Ética</b></p>
            <table class="w3-table-all" style={{ marginTop: 30 }}>
                <tr>
                    <th className="table-cor-clara">Empresa</th>
                    <th className="table-cor-escura">Sites Auditados</th>
                    <th className="table-cor-clara">Validade</th>
                    <th className="table-cor-escura">Orgão Certificador</th>
                </tr>
                
                <tr>
                    <td>AeC Centro de Contatos S.A.</td>
                    <td className="table-clar">Rio de Janeiro</td>
                    <td>Em processo de recertificação</td>
                    <td className="table-clar">BVQI</td>

                </tr>
                <tr>
                    <td>Atento Brasil S.A</td>
                    <td className="table-clar">Nova São Paulo República I Brasilprev I</td>
                    <td>18/01/2022</td>
                    <td className="table-clar">FCAV</td>

                </tr>
                <tr>
                    <td>Audac Serv. Especializados de Atendimento ao Cliente S.A.</td>
                    <td className="table-clar">Poá</td>
                    <td>25/09/2021</td>
                    <td className="table-clar">BVQI</td>

                </tr>

                <tr>
                    <td>BK Consultoria e Serviços Ltda</td>
                    <td className="table-clar">IAMSPE</td>
                    <td>29/01/2022</td>
                    <td className="table-clar">BSI</td>

                </tr>

                <tr>
                    <td>Brasilprev Previdência e Seguros S.A.</td>
                    <td className="table-clar">Sede Barra Funda</td>
                    <td>18/08/2022</td>
                    <td className="table-clar">FCAV</td>

                </tr>
                <tr>
                    <td>BR BPO Tecnologia e Serviços S.A.</td>
                    <td className="table-clar">Hipódromo</td>
                    <td>18/01/2022</td>
                    <td className="table-clar">FCAV</td>

                </tr>

                <tr>
                    <td>Central IT Tecnologia da Informação Ltda</td>
                    <td className="table-clar">Sede-Central IT Filial-Central IT</td>
                    <td>23/12/2021</td>
                    <td className="table-clar">BSI</td>

                </tr>

                <tr>
                    <td>Connectcom Teleinformática Com. Serv. Ltda.</td>
                    <td className="table-clar">Água Branca I</td>
                    <td>16/10/2021</td>
                    <td className="table-clar">FCAV</td>

                </tr>

                <tr>
                    <td>CSU CardSystem S.A.</td>
                    <td className="table-clar">Alphaview</td>
                    <td>18/11/2021</td>
                    <td className="table-clar">FCAV</td>

                </tr>

                <tr>
                    <td>Explorer Call Center Ltda.</td>
                    <td className="table-clar">Explorer Corsan</td>
                    <td>07/12/2021</td>
                    <td className="table-clar">ABS</td>

                </tr>

                <tr>
                    <td>Flex Gestão de Relacionamento S.A.</td>
                    <td className="table-clar">Dib Mussi</td>
                    <td>18/03/2022</td>
                    <td className="table-clar">BVQI</td>

                </tr>

                <tr>
                    <td>GEAP Autogestão em Saúde</td>
                    <td className="table-clar">GETEL</td>
                    <td>18/09/2021</td>
                    <td className="table-clar">FCAV</td>

                </tr>

                <tr>
                    <td>Kainos Soluções em Atendimento Ltda.</td>
                    <td className="table-clar">Kainos - SP</td>
                    <td>13/08/2022</td>
                    <td className="table-clar">ABS</td>

                </tr>

                <tr>
                    <td>Imperatriz Tele Serviços Ltda.</td>
                    <td className="table-clar">Imperatriz Tele Serviços</td>
                    <td>23/12/2021</td>
                    <td className="table-clar">ABS</td>

                </tr>

                <tr>
                    <td>Proxis Integração Comercial e Serviços Ltda.</td>
                    <td className="table-clar">P1</td>
                    <td>11/03/2022</td>
                    <td className="table-clar">ABS</td>

                </tr>

                <tr>
                    <td>Quanta Previdência Unicred</td>
                    <td className="table-clar">Quanta Previdência Unicred–Gerência de Relacionamento</td>
                    <td>23/12/2021</td>
                    <td className="table-clar">ABS</td>

                </tr>

                <tr>
                    <td>Sollo Brasil Serviços de Call Center</td>
                    <td className="table-clar">Matriz e Vitória</td>
                    <td>20/07/2022</td>
                    <td className="table-clar">BVQI</td>

                </tr>

                <tr>
                    <td>TMKT Serviços de Marketing Ltda.</td>
                    <td className="table-clar">	ZL - Poá</td>
                    <td>22/02/2022</td>
                    <td className="table-clar">FCAV</td>

                </tr>

                <tr>
                    <td>TSJ Telemarketing Ltda.</td>
                    <td className="table-clar">Reduto</td>
                    <td>22/09/2022</td>
                    <td className="table-clar">FCAV</td>

                </tr>

                <tr>
                    <td>Uranet Projetos e Sistemas</td>
                    <td className="table-clar">Bom Retiro, Ipanema, Líbero, 7 de Abril</td>
                    <td>03/03/2022</td>
                    <td className="table-clar">FCAV</td>

                </tr>
                <tr>
                    <td>Vanerven Soluções em Tecnol. e Teleatendimento</td>
                    <td className="table-clar">Matriz e TJDFT</td>
                    <td>01/12/2021</td>
                    <td className="table-clar">FCAV</td>

                </tr>

            </table>

            <p className="p-title-info"><b>
                Perfil de Maturidade</b></p>

            <table class="w3-table-all" style={{ marginTop: 30 }}>
                <tr>
                    <th className="table-cor-clara">Empresa</th>
                    <th className="table-cor-escura">Sites Auditados</th>
                    <th className="table-cor-clara">Validade</th>
                    <th className="table-cor-escura">Orgão Certificador</th>
                </tr>

                <tr>
                    <td>Actionline Telemarketing do Brasil S.A.</td>
                    <td className="table-clar">Paulista</td>
                    <td>14/09/2022</td>
                    <td className="table-clar">FCAV</td>

                </tr>


                <tr>
                    <td>Atento Brasil S.A</td>
                    <td className="table-clar">Nova São Paulo, República I, Brasilprev I</td>
                    <td>18/01/2022</td>
                    <td className="table-clar">FCAV</td>

                </tr>
                <tr>
                    <td>Brasilprev Previdência e Seguros S.A.</td>
                    <td className="table-clar">Sede, Barra Funda</td>
                    <td>18/08/2022</td>
                    <td className="table-clar">FCAV</td>

                </tr>
                <tr>
                    <td>BR BPO Tecnologia e Serviços S.A.</td>
                    <td className="table-clar">	Hipódromo</td>
                    <td>18/01/2022</td>
                    <td className="table-clar">FCAV</td>

                </tr>

                <tr>
                    <td>Central IT Tecnologia de Informação Ltda.</td>
                    <td className="table-clar">Sede-Central IT, Filial-Central IT</td>
                    <td>23/12/2021</td>
                    <td className="table-clar">BSI</td>

                </tr>
                <tr>
                    <td>CSU CardSystem S.A.</td>
                    <td className="table-clar">Alphaview</td>
                    <td>18/11/2021</td>
                    <td className="table-clar">FCAV</td>

                </tr>

                <tr>
                    <td>GEAP Autogestão em Saúde</td>
                    <td className="table-clar">GETEL</td>
                    <td>18/09/2021</td>
                    <td className="table-clar">FCAV</td>
                </tr>

                <tr>
                    <td>TSJ Telemarketing Ltda.</td>
                    <td className="table-clar">Reduto</td>
                    <td>22/09/2022</td>
                    <td className="table-clar">FCAV</td>

                </tr>

                <tr>
                    <td>Uranet Projetos e Sistemas</td>
                    <td className="table-clar">Líbero</td>
                    <td>03/03/2022</td>
                    <td className="table-clar">FCAV</td>

                </tr>

                <tr>
                    <td>Vanerven Soluções em Tecnol. e Teleatendimento</td>
                    <td className="table-clar">Matriz e TJDFT</td>
                    <td>01/12/2021</td>
                    <td className="table-clar">FCAV</td>

                </tr>
            </table>

            <div>
                <p className="p-title-info">Empresas em Certificação</p>
                35 empresas estão em fase de certificação do Selo de Ética e/ou Perfil de Maturidade.
            </div>
        </div>
    )
}

export default EmpresaCertificado;