import React from 'react';
import * as moment from "moment";

import './ticket.css';

export default function Ticket({ ticket }) {
	let color = '#3E7EA9';
	let objetivo = ticket.objetivo;

	const getArtigo = (artigo) => {

		switch (artigo.toLowerCase()) {
			case 'a13':
				return 'Art. 13 – Remoção dos dados da lista de contatos ativos. '

			case 'a15':
				return 'Art. 15 – Contato fora do horário permitido (de seg a sex, das 9h às 21h, sábados, das 10h às 16h).'

			case 'a15p3':
				return 'Art. 15, parágrafo 3º - Novo contato para a mesma oferta dentro do prazo de 60 dias após a recusa.'

			case 'a15p4':
				return 'Art. 15, parágrafo 4º - Realização de mais de 3 chamadas telefônicas no mesmo dia.'

			case 'a15p5':
				return 'Art. 15, parágrafo 5º - Realização de nova oferta de produto/serviço dentro do prazo de 6 (seis) meses após o cancelamento.'
			default: 
				return '';
		}
	}


	if (objetivo.toLowerCase() === '1') {
		objetivo.ticket = 'Oferta de Produto/Serviço.'
	} else if (objetivo.toLowerCase() === '2') {
		objetivo.ticket = 'Cobrança de Valores (Atividade não regulamentada pelo Código de Ética).'
	}


	if (ticket.status.includes('respondido')) {
		color = '#AEAEAE';
	} else if (ticket.status.includes('aberto')) {
		color = '#38DD90';
	}

	return (
		<div className='container-ticket-body'>
			<div className='borda-ticket-status' style={{ background: color }} />
			<div className='container-ticket'>
				<div className='container-ticket-top'>
					<div className='container-ticket-left'>
						<div className='ticket-left-items'>
							<div className='text-atendimento'>ID do Atendimento: {ticket.id_atendimento}</div>
							<div className='container-ticket-data-empresa'>
								<div className='text-data'>Data: {moment(ticket.createdAt).format('DD/MM/YYYY')}</div>
								<div className='text-empresa'>Empresa: {ticket.empresa}</div>
							</div>

							<div className='text-status'>
								Status:
									<span className='ticket-status-color' style={{ color: color }}>
									{' ' + ticket.status}
								</span>
							</div>

						</div>
					</div>


				</div>

				<div id={ticket._id} className='container-ticket-bottom'>
					<div className='container-ticket-linha'>
						<div className='ticket-linha' />
					</div>

					<div className='ticket-objetivo'>
						<div className='ticket-objetivo-1'>
							Objetivo da ligação que violou o Código de Ética do Probare:
							</div>
						<div className='ticket-objetivo-2'>{objetivo}</div>
					</div>
					<div className='ticket-artigo'>
						<div className='ticket-artigo-1'>Violação ao Código de Ética:</div>
						<div className='ticket-artigo-2'>{getArtigo(ticket.artigo)}</div>
					</div>

					<div className='ticket-dados-linha-1'>
						<div className='ticket-telefone'>
							<div className='ticket-telefone-1'>Telefone que Efetuou a Ligação:</div>
							<div className='ticket-telefone-2'>{ticket.telefone}</div>
						</div>
						<div className='ticket-empresa'>
							<div className='ticket-empresa-1'>Contatado em nome de:</div>
							<div className='ticket-empresa-2'>{ticket.empresa}</div>
						</div>
					</div>

					<div className='ticket-dados-linha-2'>
						<div className='ticket-data'>
							<div className='ticket-data-1'>Data de Contato:</div>
							<div className='ticket-data-2'>{ticket.data}</div>
						</div>
						<div className='ticket-horario'>
							<div className='ticket-horario-1'>Horário de contato:</div>
							<div className='ticket-horario-2'>{ticket.horario}</div>
						</div>
					</div>

					<div className='container-ticket-linha'>
						<div className='ticket-linha' />
						<div className="ticket-resposta" >
							<span className="text-resposta">Resposta: </span>
							<span style={{ color: color }}><b>{' ' +ticket.resposta}</b></span>
						</div>
						
					</div>
				</div>
			</div>
		</div>
	);
}