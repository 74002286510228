import React, { Component } from "react";
import "./stylePrincipal.css";
import Logo from '../../img/logo_probare.svg'
import { Link } from 'react-router-dom'

class Probare extends Component {
  render() {
    return (
      <div >
        <div className="title">
          <div className="manipula-img">
            <img src={Logo} alt="Logo" className="img-logo"></img>
          </div>
          <div className="header-title">
            <span className="subtitle-1">
              <b>Ouvidoria do Programa de Auto-Regulamentação</b>
            </span>
            <span className="subtitle-2">
              <b>NOTICIA DE VIOLAÇÃO AO CÓDIGO DE ÉTICA</b>
            </span>
          </div>
        </div>
        
        <div className="texto-principal">
          <p className="paragrafo-1">
            O Probare tem como objetivo promover a conduta ética de contatos com
            o consumido, através do Código de Ética do Probare que define aquilo
            que as empresas aderentes a esse programa se comprometem a fazer ou
            a não fazer na relação com o consumidor.
          </p>
          <p className="paragrafo-1">
            Já a Ouvidoria do Probare desempenha o papel de conferir ao cidadão
            a possibilidade denunciar violação a esse Código de Ética. Conheça o
            código de ética, clicando aqui Veja ainda um vídeo que explica as
            principais regras do Código de Ética do Probare clicando aqui
          </p>
          <p className="paragrafo-1">
            As reclamações que chegarem ao conhecimento da Ouvidoria serão
            consolidadas e utilizadas no âmbito do processo de renovação do Selo
            de Étifca das empresas aderentes aos programa, assim como poderão
            ser encaminhadas para o Poder Público para eventuais providências.
          </p>
        </div>
        <div className="bord-align-principal">
                    <div className="bord-principal" />
                </div>

        <div className="area-button">
          <a href="https://wa.me/551149493310?text=Ol%C3%A1">
            <button className="whatsButton">Envie pelo WhatsApp</button>
          </a>
          <span className="border-one"></span>
          
          <Link to="/ouvidoria">
          <button className="loginButton">Envie Online</button>
          </Link>
        </div>
      </div>
    );
  }
}

export default Probare;
