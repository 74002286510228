import React from 'react';

function Sobre() {
    return (
        <div>
            <p className="p-title-info"><b>
                SOBRE A OUVIDORIA</b></p>

            <p className="text-p-info">A Ouvidoria é responsável por dar tratamento formal às manifestações
            relacionadas ao comportamento das empresas/contratantes baseando-se nas diretrizes do Código de Ética.</p>

            <p className="text-p-info">A Ouvidoria se ocupa exclusivamente de violações ao Código de Ética. 
            Se alguém foi mal atendido de forma isolada, se houve problemas de entrega ou problemas com produtos 
            ou serviços vendidos, a parte interessada deverá recorrer a outros organismos, tais como PROCON ou outras 
            instâncias de defesa do consumidor, 
            especialmente a plataforma Consumidor.gov.br. A Ouvidoria não intermedia problemas entre consumidor 
            e empresas/contratantes não previstas regradas pelo Código de Ética.</p>

            <p className="text-p-info">Consumidores em geral, que recebem ou efetuam contatos com Centrais de
             Relacionamento podem manifestar-se em relação a não observância
            de algum artigo do Código de Ética através do site www.probare.org.br no item Fale com a Ouvidoria.</p>

        </div>
    )
}

export default Sobre;